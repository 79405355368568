<template>
    <h-row justify="center" align="center" v-if="!isLoading" class="mt-6">
        <h-col cols="12" sm="10" md="6" class="pa-4" no-padding>
            <v-card>

                <v-card-title>
                    <h-row justify="center">
                        <h-col cols="auto" no-padding>
                            <span>Login</span>
                        </h-col>
                    </h-row>
                </v-card-title>
                <v-card-subtitle>
                    <h-row justify="center">
                        <h-col cols="auto" no-padding>
                            <span>HAND 2 HAND</span>
                        </h-col>
                    </h-row>
                </v-card-subtitle>

                <v-divider></v-divider>

                <v-form v-model="valid" ref="form" @submit.prevent="onLogin">
                    <v-card-text>
                        <h-row justify="center">
                            <h-col cols="11">
                                <base-text-field v-model="user.EMAIL"
                                                 label="Email"
                                                 placeholder="Email"
                                                 :hide-details="false"
                                                 :rules="[v => !!v || 'L\'email è obbligatoria!',
                                                          v => $store.getters.emailRegExp.test(v) || 'L\'email deve essere valida']"
                                                 prepend-icon="fa-envelope"
                                ></base-text-field>
                                <base-text-field :type="isPasswordShow ? 'text' : 'password'"
                                                 v-model="user.PASSWORD"
                                                 label="Password"
                                                 placeholder="Password"
                                                 :hide-details="false"
                                                 :rules="[v => !!v || 'La password è obbligatoria!']"
                                                 prepend-icon="fa-hashtag"
                                                 :append-icon="isPasswordShow ? 'fa-eye-slash' : 'fa-eye'"
                                                 @click:append="isPasswordShow = !isPasswordShow"
                                ></base-text-field>
                            </h-col>
                        </h-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <h-row>
                            <h-col cols="11" no-padding>
                                <h-row justify="end">
                                    <h-col cols="auto">
                                        <v-btn text
                                               large
                                               color="primary"
                                               type="submit"
                                               :loading="isGlobalLoading || isLoading"
                                        >
                                            <span>{{ $store.getters.LOGIN_ACCEDI }}</span>
                                            <v-icon small>fa-sing-in-alt</v-icon>
                                        </v-btn>
                                    </h-col>
                                </h-row>
                            </h-col>
                        </h-row>
                    </v-card-actions>
                </v-form>

            </v-card>
        </h-col>
    </h-row>

    <loading-resouces v-else icon="fa-sign-in-alt" :messaggio="$store.getters.LOGIN_LOADING"></loading-resouces>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseTextField from '@/components/aaaGenerics/form/BaseTextField';
    import LoadingResouces from '@/components/aaaGenerics/commons/LoadingResouces';

    export default {
        name: 'Login',
        components: {HRow, HCol, BaseTextField, LoadingResouces},
        data: () => ({
            isLoading: false,
            apiTimer: null,
            valid: false,
            user: null,
            isPasswordShow: false
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            }
        },
        methods: {
            onLogin() {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        _this.$store.dispatch('defaultRequest', {
                            api: 'singin',
                            paylod: _this.user,
                            doResponse: () => {

                                let url = _this.$route.query.rdr ? (_this.$route.query.rdr + '?') : '/secure';

                                for (const key of Object.keys(_this.$route.query)) {
                                    url += key !== 'rdr' ? ('&' + key + '=' + _this.$route.query[key]) : '';
                                }

                                _this.$router.push(url);

                            },
                            doClose: () => _this.isLoading = false
                        });
                    }
                }

                if (this.$refs.form.validate()) {
                    this.isLoading = true;
                    this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                    doRequest(this);
                }
            }
        },
        created() {
            this.user = {
                EMAIL: '',
                PASSWORD: ''
            }
        },
        beforeDestroy() {
            window.clearInterval(this.apiTimer);
        }
    }
</script>

<style scoped>

</style>
